// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 16px;
  margin-bottom: 8px;
  
}

.card-title-desc {
  color: var(--#{$prefix}card-title-desc);
  margin-bottom: 24px;  
}

.card.bg-dark{
  background-color:  $black !important;
}
// input{
//   background-color: #f8f9fa !important;
// }
// .form-select{
//   background-color: #f8f9fa !important;
// }


