//
// Form-Upload
//

/* Dropzone */
.dropzone {
  height: 75px;
  border: 2px dashed var(--#{$prefix}border-color);
  background: $card-bg;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .dz-message {
    font-size: 18px;
  }

  p {
    margin: 0px;
  }
}