.action-icon{
    cursor: pointer;
    width: 100%;
}
.module-permissions-head{
  display: flex;
}

.module-permissions-head>input{
 margin-right: 5px;
}

.dropdown-menu {
    bottom: 100%;
  }
  
  .entity{
    padding: 100px;
  }

  .Module{
    width: 100%;
  }

  .status{
    width: 32em;
  }

  .d-flex{
    gap: 15px;
    justify-content:  space-between;
    
  }

  .area{
    width: 100%;
    height: 100px;
    padding: 8px 24px;
    box-sizing: border-box;
    border-style: none;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 14px;
    resize: none;
  }