*,
*:before,
*:after{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.Link{
  position: relative;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: 0.6;
}

.Link::after{
  position: absolute;
  height: 3px;
  width: 100%;
  left: 0;
  bottom: -10px;
}

.custom-offcanvas{
    width: 70% !important;
}

.NSS_templates{
    display: flex;
    gap: 20px;
  
}

.NSS_Home{
    padding-left: 500px;   
}

.top {
  overflow: hidden;
  background-color: #ffffff;
  right: 0;
  gap: 50px;
  display: flex;
  z-index: 1001;
}

.vl {
  border-left: 3px solid green;
  height: 55px;}

.top a {
  float: left;
  color: #000000;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  z-index: 100px;
}



.top a.split {
  float: right;
  background-color: #04AA6D;

}

.App {
  font-family: Arial, sans-serif;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  padding: 10px 5px;
  cursor: pointer;
}

nav ul li.active {
  border-bottom: 2px solid #28a745;
  font-weight: bold;
  color: #28a745;
}

.actions {
  display: flex;
  align-items: center;
}

.actions input {
  padding: 5px;
  margin-right: 10px;
}

.actions .add-requisition {
  padding: 5px 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  cursor: pointer;
}

.actions .add-requisition:hover {
  background-color: #218838;
}

main {
  padding: 20px;
}

body {
  font-family: Arial, sans-serif;
  display: flex;
}

.space{
  padding-left: 32px;

}

.text-decoration{
  position: relative;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: 0.5px;
}

.text-decoration:after{
  content: "";
  position: absolute;
  background-color: #27ae60;
  height: 2.50px;
  left: 5px;
  bottom: -20px;
}

.text-decoration:hover{
  color: #28a745;
}

.text-decoration:hover:after{
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  height: 56px;
  width: 69em;
  box-shadow: 0 4px 8px 0 rgba(65, 65, 65, 0.2), 0 6px 20px 0 rgba(72, 72, 72, 0.19);
  top: 0;
  background: white;
}

.header.Link{
  display:inline-block;
  padding:15px;
}

.header div {
  margin-right: 20px;
}

.header .breadcrumb {
  margin-left: auto;
  color: #888;
}

.sidebar {
  width: 200px;
  border-right: 1px solid #ddd;
}

.border{
  border-right: 1px solid black;
}

.Requisi-side{
  padding-left: 18px
}

.sidebar h2 {
  font-size: 1.2em;
  margin-left: 20px;
}

.sidebar .views {
  margin-top: 20px;
  color: #000000;
}

.sidebar .views a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #555;
  border-left: 3px solid transparent;
}

// .sidebar .views a.active {
//   background-color: #28a745;
//   color: rgb(255, 255, 255);
//   border-left: 3px solid #28a745;
// }

.sidebar .views a:hover {
  background-color: #27ae60;
  color: #ffffff;
  border-radius: 12px;
}

.sidebar .views a.create-view {
  display: flex;
  align-items: center;
  color: #555;
  border-left: none;
}

.sidebar .views a.create-view:hover {
  background-color: white;
}

.content {
  margin-left: 250px;
  padding: 20px;
  padding-top: 80px;
  width: 100%;
}


